const Text = {
    is:
`
# Persónuverndarstefna Tilkynna

Persónuverndarstefna Slidesome ehf., kt. 410618-0790. Ef þú hefur einhverjar spurningar varðandi þessa persónuverndarstefnu þá vinsamlegast hafðu samband við okkur á info@tilkynna.is.

Þegar þú heimsækir vefsíðu okkar https://tilkynna.is („vefsíðuna“), og almennt, notar einhverja þjónustu okkar („þjónustan“, þar á meðal vefsíðuna), metum við það þannig að þú treystir okkur fyrir þínum persónulegu upplýsingum. Í þessari persónuverndarstefnu leitumst við við að skýra fyrir þér á sem augljósastan hátt hvaða upplýsingum við söfnum, hvernig við notum þær og hvaða réttindi þú hefur í tengslum við þær. Við vonum að þú takir þér góðan tíma í að lesa vandlega yfir þessa persónuverndastefnu. Ef það eru einhverjir skilmálar í þessari persónuverndarstefnu sem þú ert ekki samþykk/ur skaltu hætta notkun þjónustu okkar strax.

Þegar sá aðili innan lögaðila sem tekur á móti tilkynningunni kallar eftir persónuupplýsingum þess sem tilkynnir, þá þarf viðkomandi að gefa skýringu á því og eru allar færslur skráðar í sérstakta atburðaskrá þar sem haldið er utan um allar skoðanir á gögnum. Upplýsingar um IP tölur og auðkenni tölva eru ekki skráð.
Ef skjöl og aðrar skrár eru vistaðar með tilkynningu, þá þarf að athuga hvort persónuupplýsingar í lýsigögnum skjalanna geti ljóstrað upp um auðkenni einstaklings. Þarf því að huga sérstaklega að því að fjarlægja persónuupplýsingar úr lýsigögnum ef viðkomandi vill gæta fyllstu nafnleyndar við uppljóstrun. Öllum nöfnum á skrám er breytt þegar þeim er hlaðið upp. 

Vinsamlegast lestu þessa persónuverndarstefnu vandlega til að átta þig á því hvað við gerum við upplýsingarnar sem við söfnum.

## Hvaða upplýsingar skráum við?

- ### Tilkynningar

  Þegar þú stofnar tilkynningu þá gætir þú þurft að gefa upp persónuupplýsingar eins og nafn, kennitölu, símanúmer og netfang, ásamt upplýsingum um aðila sem tilkynningin fjallar um.
  Þessi gögn eru geymd dulkóðuð með tilkynningunni og geta einungis ákveðnir aðilar hjá lögaðila fengið aðgang að þeim.

  Ef skjöl og aðrar skrár eru vistaðar með tilkynningu, þá gætu verið persónuupplýsingar í lýsigögnum skjalanna. Þarf því að huga sérstaklega að því að fjarlægja persónuupplýsingar úr lýsigögnum ef viðkomandi vill gæta fyllstu nafnleyndar við uppljóstrun. Öllum nöfnum á skrám er breytt þegar þeim er hlaðið upp.

- ### Nafnlaus samskipti

   Þegar þú sendir skilaboð í gegnum nafnlausa samskiptagátt þá gætir þú gefið upp persónuupplýsingar um þig eða aðra.


Tilkynna ábyrgist að persónuverndarréttindi þín verði virt og verði aðeins notuð eins og lýst er í þessari persónuverndarstefnu

## Leiðrétting á skráðum upplýsingum
Ef notandi vill bæta við upplýsingum varðandi málið eða leiðrétta þær upplýsingar sem fyrir eru, þá er hægt að gera það í gegnum nafnlausu gáttina með auðkennisnúmerinu og lykilorðinu sem notandi fékk þegar nafnlaus gátt var stofnuð. Athugaðu að vista auðkennisnúmerið og lykilorðið á öruggum stað því ekki er hægt að endurheimta þau ef þau glatast. Ef auðkennisnúmer og lykilorð glatast, þá er hægt að búa til nýja tilkynningu með tilvísun í fyrri tilkynningu.  


## Eyðing skráðra upplýsinga
Skráðar upplýsingar má aðeins geyma svo lengi sem þörf er á þeim. Þegar máli hefur verið lokið þá verður skráðum upplýsingum eytt úr gagnagrunni Tilkynna innan 6 mánaða frá lokun málsins. 


## Flutningur skráðra upplýsinga
Upplýsingarnar sem eru skráðar í kerfið eru venjulega ekki fluttar til þriðja aðila. Þó, undir ákveðnum kringumstæðum, geta upplýsingar verið áframsendar ef um er ræða:
- Flutning til lögfræðiþjónustu í tengslum við málshöfðun byggðri á tilkynningu.
- Tilkynning leiðir til dómsmáls.
- Að lög kveði á um að svo verði gert.


## Gagnaöryggi
Tilkynna hefur gert nauðsynlegar tæknilegar og öryggisráðstafanir til að koma í veg fyrir að persónuupplýsingar eyðileggist, glatist eða skemmist fyrir slysni, eða með ólögmætum hætti og til að koma í veg fyrir óheimila miðlun eða misnotkun persónuupplýsinganna. Öll gögn eru geymd og send dulkóðuð. Vinnsla persónuupplýsinga er háð ströngu eftirliti og verklagi og er í samræmi við góða starfshætti á þessu sviði.


## Nafnleysi og þín réttindi
Ef þú hefur gefið okkur gögn um þig þá hefur rétt á því að sjá hvaða gögn Tilkynna heldur um þig og þær upplýsingar sem þú hefur gefið í gegnum þjónustuna. Þessi réttur getur þó aldrei brotið á rétti annarra varðandi persónuvernd. Þú hefur einnig rétt á að leiðrétta rangar persónuupplýsingar um þig og þú hefur rétt á að láta eyða persónuupplýsingum um þig hvenær sem er. 	
Athugaðu ef þú gefur upp nafn þá getur nafn þitt verið notað við rannsókn málsins og einnig á síðari stigum máls, eins og t.d. í dómsmáli. 

## Hvernig notum við vefkökur?
Hægt er að nálgast upplýsingar um hvernig við notum vefkökur hér: [Vefkökur](https://tilkynna.is/is/legal/cookie-policy/)

## Spurningar
Ef þú hefur spurningar varðandi verndun persónuupplýsinga, þá geturðu haft samband við persónuverndarfulltrúa Tilkynna, Jón Trausta Arason, netfang: jon@tilkynna.is.


## Hvernig er hægt að hafa samband við Tilkynna varðandi þessa persónuverndarstefnu?
Ef þú hefur spurningar eða athugasemdir varðandi þessa persónuverndarstefnu geturðu haft samband við persónuverndarfulltrúa okkar (DPO), Jón Trausta Arason með tölvupósti á dpo@tilkynna.is.
`,
    en:
`
# Privacy Policy

## What information do we collect?
Tilkynna is an Icelandic web system that collects employees' reports of misconduct and puts them on the right track within the relevant legal entity. You can report anonymously or by name. In all cases, an anonymous portal is created where you can follow up on the notification or answer further questions. If you have chosen to provide information about yourself, we have entered the relevant information into the Tilkynna database.

When the party within the legal entity that receives the notification wants to view the personal information of the notifier, the person in question must provide an explanation and all entries are registered in a special event register where all inspections of data are kept. Information about IP addresses and computer IDs are not recorded. If documents and other files are uploaded with a notification, then it is necessary to check whether the personal information in the data of the documents can reveal the identity of an individual. Therefore, special care must be taken to remove personal information from the data if the person in question wishes to maintain full anonymity when reporting misconduct. All file names are renamed when you upload them. Tilkynna guarantees that your privacy rights will be respected without limitation and will only be used as described in this Privacy Policy.


## Correction of recorded information
If a user wants to add information about the case or correct the existing information, it can be done through the anonymous portal with the identification number and password that the user received when the anonymous portal was created. Be sure to keep your identification number and password in a safe place, as they cannot be recovered if lost. If the identification number and password are lost, you can create a new notification by referring to the previous notification.

## Deletion of registered information
Recorded information may only be stored for as long as it is needed. When a case has been closed, registered information will be deleted from the Tilkynna database within 6 months of the closing of the case.

## Transfer of registered information
The information recorded in the system is usually not transferred to third parties. However, under certain circumstances, information may be forwarded in the case of:
- Transfer to legal services in connection with litigation based on notification
- Notification leads to a court case
- That the law stipulates that this be done

## Data security
Announcing has taken the necessary technical and security measures to prevent personal data from being destroyed, lost or damaged inadvertently, or illegally, and to prevent unauthorized disclosure or misuse of personal data.

All data is stored and sent encrypted. The processing of personal information is subject to strict supervision and procedures and is in accordance with good practice in this field.


## Anonymity and your rights
If you have provided us with data about you, you have the right to see what data Tilkynna has about you, and the information you have provided through the service. However, this right can never violate the rights of others regarding privacy. You also have the right to correct incorrect personal information about yourself and you have the right to have personal information about you deleted at any time. Note that if you provide a name, your name can be used in the investigation of the case and also in later stages of the case, such as e.g. in court.


## Questions
If you have questions regarding this privacy policy you can contact Tilkynna's Data Protection Officer, Jón Trausti Arason via e-mail at jon@tilkynna.is.

`
}

export default Text
